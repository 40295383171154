import React, { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import ErrorBoundary from './ErrorBoundary';
import { deleteTopic } from '../api';  // 导入 deleteTopic API

export default function Sidebar({ topics, onSelectTopic, selectedTopicId, onSelectNewChat, isSmallScreen, setTopics }) {
    const location = useLocation();
    const navigate = useNavigate();  // 使用 useNavigate hook
    const [isSidebarOpen, setIsSidebarOpen] = useState(true);
    const [hoveredTopicId, setHoveredTopicId] = useState(null);

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    const handleDelete = async (topicId) => {
        try {
            await deleteTopic(topicId);
            navigate('/');  // 删除成功后跳转到主页
            window.location.reload();  // 强制刷新页面

        } catch (error) {
            console.error('Error deleting topic:', error);
        }
    };

    const getTopicStyle = (topicId) => {
        if (location.pathname.includes(`/topic/${topicId}`) || topicId === selectedTopicId) {
            return {
                backgroundColor: '#e0e0e0',
            };
        } else if (hoveredTopicId === topicId) {
            return {
                backgroundColor: '#f5f5f5',
            };
        }
        return {};
    };

    return (
        <ErrorBoundary>
            <div style={{ display: 'flex', height: '100%' }}>
                <div
                    style={{
                        width: isSidebarOpen ? '250px' : '0',
                        background: '#f5f5f5',
                        padding: isSidebarOpen ? '10px' : '0',
                        overflowY: 'auto',
                        transition: 'width 0.3s, padding 0.3s',
                        display: isSidebarOpen ? 'block' : 'none',
                    }}
                >
                    <ul style={{ listStyle: 'none', padding: 0 }}>
                        <li
                            key="new-chat"
                            style={{
                                marginBottom: '20px',
                                cursor: 'pointer',
                                backgroundColor: 'transparent',
                                borderRadius: '8px',
                                padding: '10px 15px',
                                display: 'flex',
                                alignItems: 'center',
                            }}
                            onClick={onSelectNewChat}
                        >
                            <img
                                src={process.env.PUBLIC_URL + '/images/logo.png'}
                                alt="AI Icon"
                                style={{ width: '20px', height: '20px', marginRight: '10px' }}
                            />
                            <Link to="/" style={{ textDecoration: 'none', color: 'inherit', flex: 1 }} tabIndex="-1">
                                New Chat
                            </Link>
                        </li>
                        {topics.map(topic => (
                            <li
                                key={topic.topic_id}
                                style={{
                                    ...getTopicStyle(topic.topic_id),
                                    marginBottom: '10px',
                                    cursor: 'pointer',
                                    borderRadius: '8px',
                                    padding: '10px 15px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    overflow: 'hidden',
                                    whiteSpace: 'nowrap',
                                    textOverflow: 'ellipsis',
                                    position: 'relative',
                                }}
                                onClick={() => onSelectTopic(topic.topic_id)}
                                onMouseEnter={() => setHoveredTopicId(topic.topic_id)}
                                onMouseLeave={() => setHoveredTopicId(null)}
                            >
                                <Link to={`/topic/${topic.topic_id}`} style={{ textDecoration: 'none', color: 'inherit', flex: 1 }} tabIndex="-1">
                                    {topic.name}
                                </Link>
                                {hoveredTopicId === topic.topic_id && (
                                    <span
                                        style={{
                                            position: 'absolute',
                                            right: '10px',
                                            display: 'inline-flex',
                                            alignItems: 'center',
                                            cursor: 'pointer',
                                            padding: '5px',
                                            borderRadius: '50%',
                                            background: 'transparent',
                                            outline: 'none',
                                        }}
                                        title="删除"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            e.target.blur(); // 移除焦点，防止出现光标
                                            const confirmDelete = window.confirm('Are you sure you want to delete this topic?');
                                            if (confirmDelete) {
                                                handleDelete(topic.topic_id);
                                            }
                                        }}
                                    >
                                        &#8230;
                                    </span>
                                )}
                            </li>
                        ))}
                    </ul>
                </div>
                {isSmallScreen && (
                    <button
                        onClick={toggleSidebar}
                        style={{
                            background: 'transparent',
                            border: 'none',
                            cursor: 'pointer',
                            position: 'absolute',
                            top: '10px',
                            left: isSidebarOpen ? '260px' : '10px',
                            transition: 'left 0.3s',
                            width: '20px',
                            height: '20px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <span
                            style={{
                                display: 'inline-block',
                                transform: isSidebarOpen ? 'rotate(45deg)' : 'rotate(225deg)',
                                transition: 'transform 0.3s',
                                width: '10px',
                                height: '10px',
                                borderLeft: '2px solid black',
                                borderBottom: '2px solid black',
                            }}
                        />
                    </button>
                )}
            </div>
        </ErrorBoundary>
    );
}
