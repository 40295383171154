// api.js

// mockMode 为 true 表示使用假数据，false 表示使用真实 API
const mockMode = false;

// 定义假数据
const mockUserInfo = {
    data: {
        name: "测试用户",
        avatar: process.env.PUBLIC_URL + '/images/user-avatar.png',
    },
};

const mockTopics = [
    {
        topic_id: "1",
        name: "话题 1",
        updated_at: Date.now(),
    },
    {
        topic_id: "2",
        name: "话题 2",
        updated_at: Date.now() - 10000,
    },
];

const mockMessages = [
    {
        role: "user",
        content: "这是用户的消息。",
    },
    {
        role: "bot",
        content: "以下是Python语言中快速排序算法的实现：\n" +
            "```\n" +
            "def quicksort(arr):\n" +
            "    if len(arr) <= 1:\n" +
            "        return arr\n" +
            "   ivot = arr[len(arr) // 2]\n" +
            "   left = [x 为 x 在 arr 如果 x <ivot]\n" +
            "    middle = [x 为 x 在 arr 如果 x ==ivot]\n" +
            "    right = [x 为 x 在 arr 如果 x >ivot]\n" +
            "    return quicksort(left) + middle + quicksort(right)\n" +
            "\n" +
            "# 示例用法：\n" +
            "arr = [5, 2, 9, 1, 7, 3, 6, 8, 4]\n" +
            "打印(快速排序(arr))  # 输出：[1, 2, 3, 4, 5, 6, 7, 8, 9]\n" +
            "```\n" +
            "这个实现使用的是\"Lomuto\"分区方案，这是标准的\"霍尔\"分区方案的一种变体，在实践中稍微快一点，效率更高。\n" +
            "\n" +
            "注意，这是一个递归实现，对于非常大的数据集可能效率不高。如果你需要排序非常大的数据集，你可能需要考虑使用迭代实现。\n" +
            "\n" +
            "此外，这个实现的平均时间复杂度是O(n log n)，但在最坏的情况下可能是O(n^2)，如果选择的ivot不合适。为了避免这种情况，你可以使用更健壮的ivot选择方法，例如\"三个中位数\"方法。\n" +
            "\n" +
            "如果你有任何问题或者想看迭代实现，请告诉我！ 😊" ,
    },
];

// 使用真实或模拟的 fetchWithAuth 函数
export async function fetchWithAuth(url, options = {}) {
    if (mockMode) {
        // 在 mock 模式下，直接返回成功的响应
        return { ok: true, json: async () => ({}) };
    }

    try {
        const response = await fetch(url, options);
        if (response.status === 401) {
            console.log("Received 401 Unauthorized");
            window.location.href = "https://talans.cn/account/app/wx_work?mode=common&appid=ww8c593a55a08d85dd&cb=http%3A%2F%2Fchat.talans.cn&agentid=1000007";
            return;
        }

        if (!response.ok) {
            console.log("Response not OK");
            throw new Error('Network response was not ok');
        }
        return await response.json();
    } catch (error) {
        console.error('Error details:', error.message, error.stack);
        throw error;
    }
}

// 获取用户信息
export async function fetchUserInfo() {
    if (mockMode) {
        // 返回模拟用户信息
        return mockUserInfo;
    }
    return fetchWithAuth('https://talans.cn/account/api/user/info', { credentials: 'include' });
}

// 获取话题列表
export async function fetchTopics() {
    if (mockMode) {
        // 返回模拟话题列表
        return mockTopics;
    }
    return fetchWithAuth('https://talans.cn/svr/topics/', { credentials: 'include' });
}

// 获取消息列表
export async function fetchMessages(topicId) {
    if (mockMode) {
        // 返回模拟消息列表
        return mockMessages
    }
    try {
        const response = await fetchWithAuth(`https://talans.cn/svr/messages/${topicId}/messages`, { credentials: 'include' });
        return response.messages;
    } catch (error) {
        console.error('Failed to fetch messages:', error);
        throw error;
    }
}

// 发送消息
export async function* sendMessage(topicId, role, content, options = {}) {
    if (mockMode) {
        // 使用模拟的 sendMessage 逻辑
        yield* mockSendMessage(topicId, role, content);
        return;
    }

    try {
        const post_data = {
            "topic_id": topicId || "",
            "role": role,
            "content": content
        };
        const req_data = JSON.stringify(post_data);
        const response = await fetch('https://talans.cn/svr/chat/stream', {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            },
            body: req_data,
            signal: options.signal,
        });

        if (response.status === 401) {
            console.log("Received 401 Unauthorized");
            window.location.href = "https://talans.cn/account/app/wx_work?mode=common&appid=ww8c593a55a08d85dd&cb=http%3A%2F%2Ftalans.cn%2Fchat&&agentid=1000007";
            return;
        }

        if (!response.body || !response.body.getReader) {
            const data = await response.json();
            yield data;
            return;
        }

        const reader = response.body.getReader();
        const decoder = new TextDecoder();
        let done = false;
        let first_message = true;
        let chat_resp = {
            "topic_id": "",
            "topic_name": "",
            "chat_resp_data": "",
        };
        while (!done) {
            const { value, done: readerDone } = await reader.read();
            done = readerDone;
            let temp_data = decoder.decode(value, { stream: true });
            if (first_message) {
                first_message = false;
                let initial_data = {}
                try {
                    initial_data = JSON.parse(temp_data);
                } catch (error) {
                    console.error('Failed parse first_message:', temp_data, "err:", error);
                    throw error;
                }

                chat_resp["topic_id"] = initial_data["topic_id"];
                chat_resp["topic_name"] = initial_data["topic_name"];
            } else {
                chat_resp["chat_resp_data"] += temp_data;
            }

            yield chat_resp;
        }
    } catch (error) {
        console.error('Failed to send message:', error);
        throw error;
    }
}

// 删除话题
export async function deleteTopic(topicId) {
    if (mockMode) {
        return { ok: true, json: async () => ({}) };
    }

    try {
        await fetchWithAuth(`https://talans.cn/svr/topics/${topicId}`, {
            method: 'DELETE',
            credentials: 'include',  // 处理跨域请求时的重要设置
            headers: {
                'Content-Type': 'application/json'
            }
        });
    } catch (error) {
        console.error('Error deleting topic:', error);
        throw error;
    }
}
