import React, { useState, useEffect } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import Sidebar from './Sidebar';
import ChatWindow from './ChatWindow';
import { fetchTopics, fetchUserInfo } from '../api';

function MainFrame() {
    const [topics, setTopics] = useState([]);
    const [selectedTopicId, setSelectedTopicId] = useState(null);
    const [user, setUser] = useState(null);
    const navigate = useNavigate();

    // State to check if the screen is small
    const [isSmallScreen, setIsSmallScreen] = useState(false);

    useEffect(() => {
        const checkScreenSize = () => {
            setIsSmallScreen(window.innerWidth <= 768);
        };

        checkScreenSize();

        window.addEventListener('resize', checkScreenSize);

        return () => window.removeEventListener('resize', checkScreenSize);
    }, []);

    useEffect(() => {
        async function initialize() {
            try {
                const userInfo = await fetchUserInfo();
                setUser(userInfo.data);

                const topics = await fetchTopics();
                topics.sort((a, b) => b.updated_at - a.updated_at);
                setTopics(topics);

                // Automatically select topic based on current URL
                const currentPath = window.location.pathname;
                const currentTopicId = currentPath.split('/topic/')[1];
                if (currentTopicId) {
                    setSelectedTopicId(currentTopicId);
                }
            } catch (error) {
                console.error('Initialization failed:', error);
            }
        }
        initialize();
    }, [navigate]);

    const handleNewTopic = (id, name) => {
        const newTopic = { topic_id: id, name, lastModified: Date.now(), updated_at: Date.now() };
        const newTopics = [...topics, newTopic].sort((a, b) => b.updated_at - a.updated_at);
        setTopics(newTopics);
        setSelectedTopicId(id);
        navigate(`/topic/${id}`);
    };

    const handleSelectNewChat = () => {
        setSelectedTopicId(null);
        navigate('/');
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'column', height: '100vh', overflow: 'hidden' }}>
            <header style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', height: "8%", padding: '10px', background: '#f5f5f5'}}>
                <div className="gradient-text">TLS Assistant</div>

                {user && (
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent:" flex-end", marginRight:"1%", minWidth:"150px"}}>
                        <span>{user.name}</span>
                        <img src={user.avatar} alt='User Avatar' style={{ width: '38px', height: '38px', borderRadius: '50%', marginLeft: '3%' }} />
                    </div>
                )}
            </header>
            <div style={{ display: 'flex', flex: 1, overflow: 'hidden' }}>
                <Sidebar
                    topics={topics}
                    onSelectTopic={setSelectedTopicId}
                    selectedTopicId={selectedTopicId}
                    onSelectNewChat={handleSelectNewChat}
                    isSmallScreen={isSmallScreen}
                    setTopics={setTopics}
                    navigate={navigate}
                />
                {user && (
                    <Routes>
                        <Route
                            path="/topic/:topicId"
                            element={
                                <ChatWindow
                                    key={selectedTopicId}
                                    onNewTopic={handleNewTopic}
                                    topics={topics}
                                    setTopics={setTopics}
                                    selectedTopicId={selectedTopicId}
                                    setSelectedTopicId={setSelectedTopicId}
                                    isSmallScreen={isSmallScreen}
                                />
                            }
                        />
                        <Route
                            path="/"
                            element={
                                <ChatWindow
                                    userAvatar={user.avatar}
                                    onNewTopic={handleNewTopic}
                                    topics={topics}
                                    setTopics={setTopics}
                                    selectedTopicId={selectedTopicId}
                                    setSelectedTopicId={setSelectedTopicId}
                                    isSmallScreen={isSmallScreen}
                                />
                            }
                        />
                    </Routes>
                )}
            </div>
        </div>
    );
}

export default MainFrame;
