import React, { useEffect, useRef, useState } from 'react';
import Chat, { Bubble, useMessages } from '@chatui/core';
import '@chatui/core/dist/index.css';
import { fetchMessages, sendMessage } from '../api';
import { useParams, useNavigate } from 'react-router-dom';
import ErrorBoundary from './ErrorBoundary';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { dracula } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { CopyToClipboard } from 'react-copy-to-clipboard';

export default function ChatWindow({
                                       userAvatar,
                                       onNewTopic,
                                       topics,
                                       setTopics,
                                       selectedTopicId,
                                       setSelectedTopicId,
                                       isSmallScreen,
                                   }) {
    const { topicId } = useParams();
    const { messages, appendMsg, updateMsg, setTyping, resetList } = useMessages([]);
    const [isNewTopic, setIsNewTopic] = useState(!topicId);
    const [initialLoad, setInitialLoad] = useState(true);
    const [error, setError] = useState(null);
    const [streamFinished, setStreamFinished] = useState(false);
    const lastMessageIdRef = useRef(null);
    const abortControllerRef = useRef(null);
    const navigate = useNavigate();
    const [newTopicId, setNewTopicId] = useState('');
    const [newTopicName, setNewTopicName] = useState('');
    const botAvatar = process.env.PUBLIC_URL + '/images/bot-avatar.png';

    useEffect(() => {
        if (topicId && initialLoad) {
            console.log('topicId && initialLoad');
            loadMessages(topicId);
            setInitialLoad(false);
        }

        return () => {
            if (abortControllerRef.current) {
                abortControllerRef.current.abort();
            }
        };
    }, [topicId, initialLoad]);

    useEffect(() => {
        if (streamFinished && isNewTopic && newTopicId) {
            setIsNewTopic(false);
            setSelectedTopicId(newTopicId);

            const newTopics = [
                ...topics,
                {
                    topic_id: newTopicId,
                    name: newTopicName,
                    updated_at: Date.now(),
                },
            ].sort((a, b) => b.updated_at - a.updated_at);
            setTopics(newTopics);
            setSelectedTopicId(newTopicId);
            onNewTopic(newTopicId, newTopicName);

            // navigate(`/topic/${newTopicId}`, { replace: true });
            window.history.replaceState(null, '', `/topic/${newTopicId}`);
        }
    }, [streamFinished]);

    // 监听 selectedTopicId 的变化，当 selectedTopicId 为空时，清空消息列表
    useEffect(() => {
        if (!selectedTopicId) {
            resetList(); // 清空消息列表
            setInitialLoad(true); // 重新设置初始加载状态，以便加载新话题时重新加载消息
        }
    }, [selectedTopicId]);

    async function loadMessages(topicId) {
        console.log('userAvatar', userAvatar);
        try {
            const messages = await fetchMessages(topicId);
            resetList();

            messages.forEach((msg) => {
                if (isSmallScreen) {
                    appendMsg({
                        type: 'text',
                        content: { text: msg.content },
                        position: msg.role === 'user' ? 'right' : 'left'
                    });
                } else {
                    appendMsg({
                        type: 'text',
                        content: { text: msg.content },
                        position: msg.role === 'user' ? 'right' : 'left',
                        user: { avatar: msg.role === 'user' ? userAvatar : botAvatar },
                    });
                }

            });
        } catch (error) {
            console.error('Failed to load messages:', error);
            setError('Failed to load messages.');
        }
    }

    function handleSend(type, val) {
        if (type === 'text' && val.trim()) {
            if (isSmallScreen) {
                appendMsg({
                    type: 'text',
                    content: { text: val },
                    position: 'right',
                });
            } else {
                appendMsg({
                    type: 'text',
                    content: { text: val },
                    position: 'right',
                    user: { avatar: userAvatar },
                });
            }

            setTyping(true);
            setStreamFinished(false);
            processSendMessage(val);
        }
    }

    async function processSendMessage(content) {
        if (abortControllerRef.current) {
            abortControllerRef.current.abort();
        }
        abortControllerRef.current = new AbortController();
        const { signal } = abortControllerRef.current;

        const generator = sendMessage(topicId || newTopicId, 'user', content, { signal });
        let accumulatedContent = '';
        let currentMessageId = `msg-${Date.now()}`;

        try {
            for await (const data of generator) {
                if (data.topic_id && !topicId && isNewTopic) {
                    setNewTopicId(data.topic_id);
                    setNewTopicName(data.topic_name);
                }

                if (!lastMessageIdRef.current || currentMessageId !== lastMessageIdRef.current) {
                    currentMessageId = `msg-${Date.now()}`;
                    lastMessageIdRef.current = currentMessageId;
                    if (isSmallScreen) {
                        appendMsg({
                            type: 'text',
                            content: { text: '' },
                            _id: currentMessageId,
                        });
                    } else {
                        appendMsg({
                            type: 'text',
                            content: { text: '' },
                            _id: currentMessageId,
                            user: { avatar: botAvatar },
                        });
                    }
                }

                accumulatedContent = data.chat_resp_data;
                if (isSmallScreen) {
                    updateMsg(lastMessageIdRef.current, {
                        type: 'text',
                        content: { text: accumulatedContent },
                    });
                } else {
                    updateMsg(lastMessageIdRef.current, {
                        type: 'text',
                        content: { text: accumulatedContent },
                        user: { avatar: botAvatar },
                    });
                }
            }
        } catch (error) {
            if (error.name !== 'AbortError') {
                console.error('Failed to send message:', error);
                setError('Failed to send message.');
            }
        } finally {
            console.log('Stream finished');
            setStreamFinished(true);

            setTyping(false);
            abortControllerRef.current = null;
        }
    }

    function renderMessageContent(msg) {
        const { content } = msg;
        if (msg.type === 'text') {
            return (
                <Bubble>
                    <ReactMarkdown
                        remarkPlugins={[remarkGfm]}
                        components={{
                            code({ node, inline, className, children, ...props }) {
                                const match = /language-(\w+)/.exec(className || '');
                                const language = match ? match[1] : '';

                                return !inline ? (
                                    <div style={{ position: 'relative' }}>
                                        <div
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                alignItems: 'center',
                                                backgroundColor: '#333',
                                                color: '#fff',
                                                padding: '4px 8px',
                                                borderTopLeftRadius: '5px',
                                                borderTopRightRadius: '5px',
                                            }}
                                        >
                                            <span style={{ fontSize: '12px' }}>{language || 'plaintext'}</span>
                                            <CopyToClipboard text={String(children).replace(/\n$/, '')}>
                                                <button
                                                    style={{
                                                        fontSize: '12px',
                                                        backgroundColor: 'transparent',
                                                        border: 'none',
                                                        color: '#fff',
                                                        cursor: 'pointer',
                                                    }}
                                                    onClick={() => alert('代码已复制')}
                                                >
                                                    复制代码
                                                </button>
                                            </CopyToClipboard>
                                        </div>
                                        <SyntaxHighlighter
                                            style={dracula}
                                            language={language}
                                            PreTag="div"
                                            children={String(children).replace(/\n$/, '')}
                                            {...props}
                                            customStyle={{
                                                margin: 0,
                                                padding: '8px',
                                                backgroundColor: '#282c34',
                                                borderTopLeftRadius: '0px',
                                                borderTopRightRadius: '0px',
                                                borderBottomLeftRadius: '5px',
                                                borderBottomRightRadius: '5px',
                                            }}
                                        />
                                    </div>
                                ) : (
                                    <code className={className} {...props}>
                                        {children}
                                    </code>
                                );
                            },
                        }}
                    >
                        {content.text}
                    </ReactMarkdown>
                </Bubble>
            );
        }
        return null;
    }

    return (
        <ErrorBoundary>
            <div
                style={{
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    background: '#fff',
                    height: '100%',
                    overflow: 'hidden',
                }}
            >
                {error ? (
                    <div style={{ color: 'red', textAlign: 'center' }}>{error}</div>
                ) : (
                    <Chat
                        messages={messages}
                        renderMessageContent={renderMessageContent}
                        onSend={handleSend}
                        inputPlaceholder="请输入..."
                        placeholder="请输入..."
                        style={{ flex: 1, border: 'none', background: 'none' }}
                    />
                )}
            </div>
        </ErrorBoundary>
    );
}
