import React, { Component } from 'react';

class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        // 更新 state 以显示错误 UI
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        // 可以将错误信息上报给服务器
        console.error("Error caught by ErrorBoundary: ", error, errorInfo);
    }

    render() {
        if (this.state.hasError) {
            // 可以自定义错误 UI
            return <h1>出现错误，请稍后重试。</h1>;
        }

        return this.props.children;
    }
}

export default ErrorBoundary;
